import React from "react";
import background from "./imatched.png";
import backgroundDark from "./imatched_dark.png";
import tracer from "./tracer.png";
import "./rcolorfulstyle.css";
import WebFont from "webfontloader";
import FileSaver from "file-saver";

import { HexColorPicker, HexColorInput } from "react-colorful";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.tempCanvas = document.createElement("canvas");

    this.tctx = this.tempCanvas.getContext("2d");
    this.year = new Date().getFullYear();

    this.state = {
      specialty: "Anesthesiology",
      institution: "Discord University",
      specialtyFont: "Raleway",
      institutionFont: "Raleway",
      windowWidth: window.innerWidth,
      backgroundColor: "#3f3340",
      boxColor: "#695898",
      darkMainText: false,
      darkSecondaryText: false,
    };

    this.backgroundImage = new Image();

    this.updateImage = this.updateImage.bind(this);
    this.printDownload = this.printDownload.bind(this);
    this.instaDownload = this.instaDownload.bind(this);

    this.handleMainTextToggle = this.handleMainTextToggle.bind(this);
    this.handleSecondaryTextToggle = this.handleSecondaryTextToggle.bind(this);

    this.handleSpecialtyChange = this.handleSpecialtyChange.bind(this);
    this.handleInstitutionChange = this.handleInstitutionChange.bind(this);
    this.handleBackgroundColorChange =
      this.handleBackgroundColorChange.bind(this);
    this.handleBoxColorChange = this.handleBoxColorChange.bind(this);

    this.handleInstitutionFontChange =
      this.handleInstitutionFontChange.bind(this);
    this.handleSpecialtyFontChange = this.handleSpecialtyFontChange.bind(this);

    const app = this;

    WebFont.load({
      google: {
        families: ["Raleway", "Delius", "Libre Baskerville"],
      },
      active: function () {
        app.updateImage();
      },
    });
  }

  handleInstitutionFontChange = (e) => {
    this.setState({ institutionFont: e.target.value }, this.updateImage);
  };

  handleSpecialtyFontChange = (e) => {
    this.setState({ specialtyFont: e.target.value }, this.updateImage);
  };

  handleMainTextToggle = (e) => {
    this.setState({ darkMainText: e.target.checked }, this.updateImage);
  };

  handleSecondaryTextToggle = (e) => {
    this.setState({ darkSecondaryText: e.target.checked }, this.updateImage);
  };

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth }, this.updateImage);
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.updateImage();
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  handleBackgroundColorChange(newColor) {
    this.setState({ backgroundColor: newColor }, this.updateImage);
  }

  handleBoxColorChange(newColor) {
    this.setState({ boxColor: newColor }, this.updateImage);
  }

  handleSpecialtyChange(event) {
    this.setState({ specialty: event.target.value }, this.updateImage);
  }

  handleInstitutionChange(event) {
    this.setState({ institution: event.target.value }, this.updateImage);
  }

  updateImage() {
    const canvas = document.getElementById("canvas");
    const app = this;
    var backgroundImage = app.backgroundImage;
    backgroundImage.src = app.state.darkMainText ? backgroundDark : background;

    backgroundImage.onload = function () {
      const ctx = canvas.getContext("2d");
      canvas.oncontextmenu = function (e) {
        e.preventDefault();
      };
      canvas.width = 3300;
      canvas.height = 2550;
      canvas.crossOrigin = "Anonymous";
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = app.state.backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
      ctx.fillStyle = app.state.boxColor;
      ctx.fillRect(500, 1702, 2300, 340);

      ctx.fillStyle = app.state.darkMainText ? "#4f4f4f" : "white";
      ctx.font =
        "500 " +
        app.determineFontSize(Math.max(7, app.state.specialty.length - 2)) +
        " " +
        app.state.specialtyFont +
        ", Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "alphabetic";
      ctx.fillText(app.state.specialty, 3300 / 2, 1400);
      ctx.textBaseline = "middle";

      ctx.font =
        "500 " +
        app.determineFontSize(app.state.institution.length) +
        " " +
        app.state.institutionFont +
        ", Arial";
      ctx.fillStyle = app.state.darkSecondaryText ? "#4f4f4f" : "white";
      ctx.fillText(app.state.institution, 3300 / 2, 1890);

      var canvas2 = document.getElementById("hidden-canvas");
      canvas2.width = 3300;
      canvas2.height = 2550;
      var ctx2 = canvas2.getContext("2d");

      var canvas3 = document.getElementById("hidden-insta-canvas");
      var ctx3 = canvas3.getContext("2d");
      canvas3.width = 3300;
      canvas3.height = 2550;

      ctx2.drawImage(canvas, 0, 0, 3300, 2550);
      ctx3.drawImage(canvas, 0, 0, 3300, 2550);

      ctx.font = "500 88pt Raleway, Arial";
      ctx.textAlign = "center";
      ctx.fillStyle = app.state.darkMainText ? "#4f4f4f" : "white";

      ctx2.font = "500 88pt Raleway, Arial";
      ctx2.textAlign = "center";
      ctx2.fillStyle = app.state.darkMainText ? "#4f4f4f" : "white";

      ctx3.font = "500 88pt Raleway, Arial";
      ctx3.textAlign = "center";
      ctx3.fillStyle = app.state.darkMainText ? "#4f4f4f" : "white";

      ctx.fillText("#match" + app.year, 500, 2426);
      ctx2.fillText("#match" + app.year, 500, 2426);
      ctx3.fillText("#match" + app.year, 3300 / 2, 2426);

      const right_col = document.getElementById("right-column");
      const scale_factor = (right_col.offsetWidth * 0.9) / 3300;
      // scale based on column span of preview column
      app.resizeTo(canvas, scale_factor);
    };
  }

  resizeTo(canvas, pct) {
    var cw = canvas.width;
    var ch = canvas.height;
    this.tempCanvas.width = cw;
    this.tempCanvas.height = ch;
    this.tctx.drawImage(canvas, 0, 0);
    canvas.width *= pct;
    canvas.height *= pct;
    var ctx = canvas.getContext("2d");
    ctx.imageSmoothingEnabled = true;
    ctx.drawImage(this.tempCanvas, 0, 0, cw, ch, 0, 0, cw * pct, ch * pct);
  }

  determineFontSize(currentLength) {
    const minFont = 100; // at 30 characters
    const maxFont = 250; // at 6 characters

    const fontSlope = (maxFont - minFont) / 24;

    var fontSize = Math.max(
      minFont,
      maxFont - fontSlope * Math.max(6, currentLength)
    );

    return fontSize + "pt";
  }

  printDownload() {
    var canvas = document.getElementById("hidden-canvas");

    canvas.toBlob(function (blob) {
      FileSaver.saveAs(blob, "print-match-day.png");
    });
  }

  instaDownload() {
    var canvas = document.getElementById("hidden-insta-canvas");
    canvas.toBlob(function (blob) {
      FileSaver.saveAs(blob, "insta-match-day.png");
    });
  }

  render() {
    return (
      <div id="app" className="font-sans">
        <div className="header">
          <h1 className="text-white">Residency &#38; Fellowship</h1>
          <h1 className="text-white pb-2">Match Day Card Creator</h1>
          <br />
        </div>
        <div
          id="main-table md"
          className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-4"
        >
          <div
            className="frosted rounded-2xl shadow-lg grid justify-items-auto col-span-1 row-span-1"
            id="institution-column"
          >
            <h2 className="rounded-t-lg h-12">Specialty</h2>

            <input
              className="rounded-xl py-2 px-6 bg-gray-200 mx-7 mt-2"
              maxLength="30"
              type="text"
              defaultValue={this.state.specialty}
              value={this.state.value}
              onChange={this.handleSpecialtyChange}
            />
            <label
              htmlFor="font-specialty"
              className="py-2 px-6 mx-2 mt-2 text-yellow-500"
            >
              Font Style:
            </label>
            <select
              name="font-specialty"
              id="font-specialty"
              className="rounded-xl py-2 px-6 bg-gray-200 mx-7 mb-3"
              onChange={this.handleSpecialtyFontChange}
            >
              <option value="Raleway">Sans-Serif</option>
              <option value="Libre Baskerville">Serif</option>
              <option value="Delius">Playful</option>
            </select>

            <div id="dark-mode-main-checkbox" className="mt-3">
              <input
                type="checkbox"
                className="form-checkbox text-indigo-600 ml-8 mr-2 inline-block align-middle w-5 h-5"
                checked={this.darkMainText}
                onChange={this.handleMainTextToggle}
              />
              <span className="text-white text-md inline-block align-middle">
                Use Dark Text
              </span>
            </div>

            <hr className="mx-8 mt-9 mb-2" />

            <div
              id="background-color-picker"
              className="grid justify-items-auto m-3"
            >
              <label className="text-center">Background Color</label>
              <div id="hex-picker-1">
                <HexColorPicker
                  color={this.state.backgroundColor}
                  onChange={this.handleBackgroundColorChange}
                />
              </div>
              <label className="text-center mt-4">Hex#</label>
              <HexColorInput
                color={this.state.backgroundColor}
                className="rounded-2xl py-2 bg-gray-200 mx-10 text-center mb-5"
                onChange={this.handleBackgroundColorChange}
              />
            </div>
          </div>

          <div className="frosted rounded-2xl shadow-lg grid justify-items-auto col-span-1 row-span-1">
            <h2 className="rounded-t-lg h-12">Institution</h2>

            <input
              className="rounded-xl py-2 px-6 bg-gray-200 mx-7 mt-2"
              maxLength="30"
              type="text"
              defaultValue={this.state.institution}
              value={this.state.value}
              onChange={this.handleInstitutionChange}
            />

            <label
              htmlFor="font-institution"
              className="py-2 px-6 mx-2 mt-2 text-yellow-500"
            >
              Font Style:
            </label>

            <select
              name="font-institution"
              id="font-institution"
              className="rounded-xl py-2 px-6 bg-gray-200 mx-7 mb-3"
              onChange={this.handleInstitutionFontChange}
            >
              <option value="Raleway">Sans-Serif</option>
              <option value="Libre Baskerville">Serif</option>
              <option value="Delius">Playful</option>
            </select>

            <div id="dark-mode-main-checkbox" className="mt-3">
              <input
                type="checkbox"
                className="form-checkbox text-indigo-600 ml-8 mr-2 inline-block align-middle w-5 h-5"
                checked={this.darkSecondaryText}
                onChange={this.handleSecondaryTextToggle}
              />
              <span className="text-white text-md inline-block align-middle">
                Use Dark Text
              </span>
            </div>

            <hr className="mx-8 mt-9 mb-2" />

            <div id="box-color-picker" className="grid justify-items-auto m-3">
              <label className="text-center">Secondary Color</label>
              <div>
                <HexColorPicker
                  color={this.state.boxColor}
                  onChange={this.handleBoxColorChange}
                />
              </div>
              <label className="text-center mt-4">Hex#</label>
              <HexColorInput
                color={this.state.boxColor}
                className="rounded-2xl py-2 bg-gray-200 mx-10 text-center mb-5"
                onChange={this.handleBoxColorChange}
              />
            </div>
          </div>

          <div
            className="frosted  shadow-lg col-span-1 md:col-span-2	row-span-2 rounded-2xl"
            id="right-column"
          >
            <h2 className="rounded-t-lg h-12">Preview</h2>

            <div>
              <canvas id="canvas" className="shadow-lg center my-6" />
            </div>
            <div className="hidden">
              <canvas id="hidden-insta-canvas" />
              <canvas id="hidden-canvas" />
            </div>

            <div className="grid grid-cols-2 gap-1 p-5">
              <button
                id="insta-button"
                className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-2 px-4 ml-2 mr-2 rounded inline-flex items-center"
                onClick={this.instaDownload}
              >
                <span className="m-auto">Download for Instagram</span>
              </button>

              <button
                id="print-button"
                className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-2 px-4 mx-2 rounded inline-flex items-center"
                onClick={this.printDownload}
              >
                <span className="m-auto">Download for iPad/Printing</span>
              </button>
            </div>
          </div>
          <div className="frosted  shadow-lg col-span-1 md:col-span-2	row-span-1 rounded-2xl">
            <h2 className="rounded-t-lg h-12">About</h2>
            <div className="px-8 pb-8">
              <p className="text-white">
                Print these out or download them onto an iPad for Match Day
                photos!
              </p>
              <a
                href={
                  "https://www.instagram.com/explore/tags/match" + this.year
                }
              >
                <b>{"#match" + this.year}</b>
              </a>
              <p>Created using React and tailwindcss</p>
              <p>
                Dedicated to the members of the{" "}
                <b className="text-yellow-400">Anesthesia 2020-2021 Discord</b>
              </p>
              <br></br>
              <p>Suggestions welcome!</p>

              <div className="bg-gray-700 rounded-full inline-block pr-5 mt-5">
                <img src={tracer} className="inline rounded-full h-12 w-12" />
                <span className="align-middle ml-3">
                  <a
                    href="mailto:whoatenine.7@gmail.com"
                    className="text-white"
                  >
                    whoatenine#3776
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
